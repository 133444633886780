export const de = {
    
    // ClaimWinningsAllowed.js
   claim_winnings_allowed_message: "Wir prüfen derzeit Ihre Gewinne und werden Sie so schnell wie möglich kontaktieren.",

   // WithdrawAllowed.js
   withdrawal_allowed_message: ({ sign, activeWithdrawal }) => <span>Sie haben derzeit eine aktive Auszahlung für {sign}{activeWithdrawal?.amount}. Bitte warten Sie, bis wir Ihre Auszahlung bearbeitet haben, bevor Sie eine Neue anfordern</span>,

   // BonusRowCategory.js
   bonus_row_title: "Wählen Sie Ihren ersten Bonus",
   bonus_row_title_2: "Wählen Sie Ihren nächsten Bonus",

   // BonusTile.js
   bonus_tile_cta: "Jetzt beanspruchen",

   // BonusTimer.js
   bonus_timer_expired: "Läuft heute ab!",

   // Deposit.js
   deposit_btn_back_to_amount: "Zurück zum Einzahlungsbetrag",
   deposit_btn_back_to_payment_options: "Zurück zu den Zahlungsmöglichkeiten",

   // DepositAmountSection.js
   deposit_amount_title: "Betrag",
   deposit_amount_cta: "Einzahlung",
   deposit_amount_field: "Einzahlungsbetrag",
   deposit_amount_field_label: (sign) => {
       return `Einzahlungsbetrag (${sign})`
   },
   deposit_amount_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
       return `Der Einzahlungsbetrag sollte zwischen ${sign}${depositMinAmount} und ${sign}${depositMaxAmount} liegen.`
   },
   deposit_amount_bonus_limit: ({ sign, depositMinAmount, depositMaxAmount }) => {
       return `Der Einzahlungsbetrag für den gewählten Bonus sollte zwischen ${sign}${depositMinAmount} und ${sign}${depositMaxAmount} liegen.`
   },

   // DepositBonusCard.js
   deposit_bonus_card_min_deposit: "Mindestbetrag:",

   // DepositBonusSection.js
   deposit_bonus_section_title: "Wählen Sie Ihren Bonus",
   deposit_bonus_section_title_selected: "Gewählter Bonus:",

   // DepositPaymentMethod.js
   deposit_payment_method_title: "Wählen Sie Ihre Zahlungsmethode",
   deposit_payment_method_bonus: "15% Extra-Bonus",

   // DepositRedirect.js
   deposit_redirect_title: "Zahlung abschließen",
   deposit_redirect_cta: "Einzahlung",
   deposit_redirect_cta_processing: "Verarbeitung",
   deposit_redirect_success_title: "Fast geschafft!",
   deposit_redirect_success_text: "Klicken Sie unten, um aufzuladen und zu spielen.",
   deposit_redirect_success_cta: "Zahlung abschließen",
   deposit_redirect_error: (error) => {
       return `${error ? error : 'Ein Fehler ist aufgetreten.'} Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlungsmethode.`;
   },
   deposit_redirect_field_first_name: "Vorname",
   deposit_redirect_field_last_name: "Nachname",
   deposit_redirect_field_email: 'E-mail',
   deposit_redirect_field_phone: "Telefon",
   deposit_redirect_field_address: "Adresse",
   deposit_redirect_field_city: "Stadt",
   deposit_redirect_field_state: "Provinz/State",
   deposit_redirect_field_zip: "Postleitzahl",

   // DepositSummary.js
   deposit_summary_amount: "Betrag:",
   deposit_summary_bonus: "Bonus:",
   deposit_summary_no_bonus: "Kein Bonus ausgewählt.",

   // DepositToggle.js
   deposit_toggle_btn_deposit: "Einzahlung",
   deposit_toggle_btn_withdraw: "Abheben",

   // FreespinsRow.js
   freespin_row_title: ({ number, game }) => <>Sie haben<span className="">{number} Freispiele </span> in<span className="">{game}</span>!</>,
   freespin_row_cta: "Jetzt spielen",

   // GameRowJackpotTypes.js
   jackpot_types_daily: "Täglicher Jackpot",
   jackpot_types_hourly: "Stündlicher Jackpot",
   jackpot_types_drops_in: "Drops innerhalb",

   // GameSearchField.js
   game_search_input_placeholder: "Spiel oder Anbieter eingeben",
   game_search_input_hint: "Suchen",

   // IdleTimer.js
   idle_timer_title: "Sitzung abgelaufen",
   idle_timer_text: "Sie waren inaktiv. Bitte loggen Sie sich erneut ein.",
   idle_timer_cta: "Anmeldung",

   // ModalClaimBonusWinnings.js
   claim_bonus_winnings_title: "Fordern Sie Ihre Gewinne an",
   claim_bonus_winnings_processing: "Verarbeitung...",
   claim_bonus_winnings_exceeded: "Ihre Anfrage wurde zur Genehmigung weitergeleitet. Wir werden uns so schnell wie möglich bei Ihnen melden.",
   claim_bonus_winnings_error: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",

   // ModalCloseAccount.js
   close_account_title: "Konto schließen",
   close_account_text: <span>Sind Sie sicher, dass Sie Ihr Konto schließen möchten?<br /><br /><i>Um Ihre Selbstausschluss zu beenden, melden Sie sich mit Ihrer E-Mail und Ihrem Passwort an und folgen Sie den weiteren Anweisungen.</i></span>,
   close_account_cta: "Ja, mein Konto schließen",

   // ModalOpenAccount.js
   open_account_title: "Konto geschlossen",
   open_account_text: "Ihr Konto ist derzeit geschlossen. Möchten Sie Ihr Konto wieder öffnen?",
   open_account_cta: "Ja, bitte öffnen Sie mein Konto",

   // Navigation.js
   navigation_btn_home: "Startseite",
   navigation_btn_games: "Spiele",
   navigation_btn_log_in: "Anmelden",
   navigation_btn_register: "Registrieren",
   navigation_balance: "Guthaben",

   // PhoneVerification.js
   phone_verification_log_out_title: (number) => <>Erhalten Sie<span className="verify-phone-title-spins">{number}</span> ohne Einzahlung &nbsp;erforderlich!</>,
   phone_verification_log_in_title: (number) => <>Verifizieren Sie Ihre Telefonnummer und erhalten Sie <span className="verify-phone-title-spins">{number}</span>!</>, 
   phone_verification_field_code_title: "Geben Sie Ihren Bestätigungscode ein",
   phone_verification_field_phone_label: "Geben Sie Ihre Handynummer ein",
   phone_verification_field_code_label: "Code",
   phone_verification_btn_claim: "Jetzt beanspruchen",
   phone_verification_btn_verify_claim: "Verifizieren und beanspruchen",
   phone_verification_btn_sms: "SMS",
   phone_verification_btn_phone_call: "Anruf",
   phone_verification_btn_confirm: "Bestätigen",
   phone_verification_btn_cancel: "Abbrechen",
   phone_verification_btn_try_again: "Erneut versuchen",
   phone_verification_confirmation_title: "Ist diese Nummer korrekt?",
   phone_verification_error_phone_already_verified: "Dieses Telefon wurde bereits verifiziert.",
   phone_verification_error_wrong_phone: "Diese Telefonnummer stimmt nicht mit der Telefonnummer in Ihrem Profil überein.",
   phone_verification_error_active_bonus: "Sie haben bereits einen Bonus. Bitte aktualisieren Sie die Seite.",
   phone_verification_error_general: "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihr Telefon und versuchen Sie es erneut.",
   phone_verification_example:  "Zum Beispiel:",

   // ProfilePlayerInformation.js
   profile_information_title: "Rechnungsinformationen",
   profile_information_btn_save: "Speichern",
   // profile_information_change_phone: "Note: if you wish to change your number, please contact support.", "Hinweis: Wenn Sie Ihre Telefonnummer ändern möchten, wenden Sie sich bitte an den Support."
   profile_information_field_first_name: "Vorname",
   profile_information_field_last_name: "Nachname",
   profile_information_field_email: "E-mail",
   profile_information_field_phone: "Telefon",
   profile_information_field_address: "Adresse",
   profile_information_field_city: "Stadt",
   profile_information_field_state: "Provinz/Region",
   profile_information_field_zip: "Postleitzahl",
   profile_information_field_dob: "Geburtsdatum",
   profile_information_notifications_title: 'Benachrichtigungseinstellungen',
    profile_information_notifications_updates: 'Erhalten Sie Benachrichtigungen über Kontoaktualisierungen, Einzahlungen, Abhebungen, Ausfallzeiten und geplante Wartungsarbeiten.',
    profile_information_notifications_weekly: 'Erhalten Sie Benachrichtigungen zu kostenlosen wöchentlichen Angeboten, Bonus-Updates, neuen Aktionen und Spieleveröffentlichungen.',

   // RegistrationForm.js
   registration_form_email_label: "E-Mail-Adresse",
   registration_form_or: "ODER",
   registration_form_email_placeholder: "name@beispiel.com",
   registration_form_password_label: "Passwort",
   registration_form_key_hint: "Registrieren",
   registration_form_terms: <span>Ich bin 18 Jahre alt und akzeptiere <a href="https://cepgucehw.gleap.help/en/articles/4-privacy-policy" target="_blank">Datenschutzrichtlinie</a> und <a href="https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions" target="_blank">Allgemeine&nbsp;Geschäftsbedingungen</a>.</span>,
   registration_form_btn_cta: "Kostenloses Konto erstellen",
   registration_form_verify_email_title: "Bitte bestätigen Sie Ihre E-Mail-Adresse",
   registration_form_verify_email_subtitle: "Wir haben eine E-Mail an die von Ihnen angegebene Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang und folgen Sie den Anweisungen, um Ihr Konto zu aktivieren.",
   registration_form_error_email: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
   registration_form_error_email_exists: <span>Diese E-Mail-Adresse existiert bereits.<br />Versuchen Sie es <a href="?login">vielleicht mit anmelden</a>?</span>,
   registration_form_error_password: "Das Passwort muss mindestens 7 Zeichen lang sein.",
   registration_form_error_terms: "Bitte stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu, um die Registrierung abzuschließen.",
   registration_form_error_general: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.",


   // WeeklyBonus.js
   weekly_bonus_cta: "Jetzt beanspruchen",

   // Withdraw.js
   withdraw_balance: "Verfügbares Guthaben",
   withdraw_bonus_balance: "Bonusguthaben",
   withdraw_wager_progress: "Wettfortschritt",
   withdraw_choose_amount: "Betrag für die Auszahlung eingeben",
   withdraw_choose_amount_label: (sign) => `Betrag (${sign})`,
   withdraw_error_insufficient_funds: "Sie haben keine verfügbaren Gelder zum Abheben",
   withdraw_error_wrong_amount_limit_min: ({ sign, minAmount }) => `Unzureichendes Guthaben. Der Mindestabhebungsbetrag beträgt ${sign}${minAmount}`,
   withdraw_error_wrong_amount_limit_min_max: ({ sign, minAmount, maxAmount }) => `Der Abhebungsbetrag muss zwischen ${sign}${minAmount} und ${sign}${maxAmount} liegen.`,
   withdraw_btn_back_to_payment_options: "Zurück zu den Auszahlungsoptionen",

   // WithdrawMethod.js
   withdraw_method_interac_legal: "®Handelsmarke der Interac Corp. Unter Lizenz verwendet.",
   withdraw_method_choose_amount: "Bitte geben Sie einen Betrag ein, um fortzufahren.",

   // WithdrawRedirect.js
   withdraw_redirect_title: "Abschluss Ihrer Auszahlung",
   withdraw_redirect_cta: "Auszahlen",
   withdraw_redirect_cta_processing: "Verarbeitung...",
   withdraw_redirect_success_title: "Erfolg!",
   withdraw_redirect_success_text_1: "Ihre Auszahlungsanforderung wird bearbeitet und sollte je nach Auszahlungsart innerhalb von 1 bis 5 Werktagen abgeschlossen sein.",
   withdraw_redirect_success_text_2: "Sobald Ihre Auszahlung bearbeitet wurde, wird der gewählte Betrag von Ihrem Kontostand abgezogen.",
   withdraw_redirect_error: (error) => {
       return `${error ? error : 'Bitte versuchen.'} Sie es erneut oder wählen Sie eine andere Auszahlungsmethode.`;
   },
   withdraw_redirect_field_first_name: "Vorname",
   withdraw_redirect_field_last_name: "Nachname",
   withdraw_redirect_field_email: "E-mail",
   withdraw_redirect_field_phone: "Telefon",
   withdraw_redirect_field_address: "Adresse",
   withdraw_redirect_field_city: "Stadt",
   withdraw_redirect_field_state: "Provinz/Region",
   withdraw_redirect_field_zip: "Postleitzahl",
   withdraw_redirect_field_card_number: "Kartennummer",
   withdraw_redirect_field_exp_month: "Ablaufmonat (MM)",
   withdraw_redirect_field_exp_year: "Ablaufjahr (JJ)",
   withdraw_redirect_wallet_id: "Wallet-ID",

   // WithdrawSummary.js
   withdrawal_summary_amount: "Betrag:",

   // ForgotPassword.js
   forgot_password_title: "Passwort zurücksetzen",
   forgot_password_field_email: "Geben Sie Ihre Registrierungs-E-Mail ein",
   forgot_password_text_1: "Überprüfen Sie Ihren Posteingang und Ihren Spam-Ordner.",
   forgot_password_text_2: "Wenn Sie innerhalb von 30 Minuten keine E-Mail erhalten, versuchen Sie bitte, dieses Formular erneut abzusenden, oder kontaktieren Sie den Support.",
   forgot_password_btn_close: "Schließen",
   forgot_password_btn_submit: "Absenden",

   // Login.js
   login_forgot_password_cta: "Passwort vergessen?",
   login_create_account: "Noch kein Konto?",
   login_create_account_cta: "Registrieren",
   login_btn_close: "Schließen",
   login_cta: "Anmelden",

   // Profile.js
   profile_title: "Kundenprofil",
   profile_balance: "Gesamtguthaben:",
   profile_balance_withdrawable: "Auszahlbares Guthaben:",
   profile_balance_bonus: "Bonusguthaben:",
   profile_wager_progress: "Umsatzfortschritt:",
   profile_bonus_promocode: "Promocode:",
   profile_bonus_expires: "Gültig bis",
   profile_btn_claim_winnings: "Fordern Sie Ihre Gewinne an",
   profile_btn_bonus_cancel: "Bonus stornieren",
   profile_btn_support: "Support kontaktieren",
   profile_close_account: "Achtung! Um die Schließung Ihres Kontos einzuleiten, drücken Sie bitte die Schaltfläche unten.",
   profile_btn_close_account: "Konto schließen",
   profile_btn_log_out: "Abmelden",

   // RegionBlocked.js
   region_blocked_title: "Region blockiert.",

   // ResetPassword.js
   reset_password_title: "Passwort zurücksetzen",
   reset_password_field_new_password: "Neues Passwort",
   reset_password_field_new_password_error: "Bitte geben Sie Ihr Passwort ein",
   reset_password_field_confirm_password: "Passwort bestätigen",
   reset_password_field_confirm_password_error: "Bitte geben Sie Ihr Passwort erneut ein",
   reset_password_error: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie, den Zurücksetzungslink erneut zu verwenden, oder kontaktieren Sie den Support.",
   reset_password_error_match: "Passwörter stimmen nicht überein! Bitte korrigieren Sie dies und versuchen Sie es erneut.",
   reset_password_cta: "Passwort zurücksetzen",
   reset_password_redirect_text: "Weiterleitung zur Startseite...",

   // VerifyEmail.js
   verify_email_success_title: "Erfolg! Ihre E-Mail wurde verifiziert",
   verify_email_success_subtitle: "Sie werden nun zur Anmeldeseite weitergeleitet...",
   verify_email_error_title: "Ihr Konto ist bereits verifiziert",
   verify_email_error_subtitle: <span>Ihr Konto ist bereits verifiziert. Wenn Sie Probleme beim Einloggen haben, senden Sie uns bitte eine E-Mail an <b>info@orozinosupport.com</b>.</span>,
   verify_email_error: "Der Verifizierungstoken ist ungültig"
}