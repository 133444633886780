export const fr_brand = {
    /******************/
   /** Page titles */
   page_home: "Accueil | Orozino",
   page_games: "Jeux | Orozino",
   page_game: "Jeu | Orozino",
   page_login: "Connexion | Orozino",
   page_registration: "Inscription | Orozino",
   page_cashier: "Caisse | Orozino",
   page_profile: "Profil | Orozino",
   page_forgot_password: "Mot de passe oublié | Orozino",
   page_reset_password: "Réinitialiser le mot de passe | Orozino",
   page_region_blocked: "Région bloquée | Orozino",
   page_verify_email: "Vérification de l'email | Orozino",

   /******************/
   /** Brand Pages */

   // Home
   last_played_10: "Reprenez le jeu",
   last_played_20: "Capsule temporelle",

   /******************/
   /** Brand components */

   // CashbackRoll.js
   cashback_roll_title: "Choisissez & Gagnez !",
   cashback_roll_title_deposit: "Rechargez et tentez votre chance !",
   cashback_roll_subtitle: <span>Débloquez votre argent en réclamant votre premier&nbsp;bonus!</span>,
   cashback_roll_subtitle_first_deposit: "Choisissez une carte et gagnez de l'argent instantanément !",

   cashback_roll_next_ticket: "Prochaine sélection disponible dans :",
   cashback_roll_deposit_title: "Déposez et gagnezde l’argent instantanement !",
   cashback_roll_btn_deposit: "Déposez maintenant",
   cashback_roll_tickets_left: "Billets :",
   cashback_roll_you_won: "Vous avez gagné",
   cashback_roll_notification: ({ sign, amount }) => <span><span style={{ fontWeight: "600" }}>{sign}{amount}</span> a été ajouté à votre compte</span>,

   // HomeBanner.js
   home_banner_log_out_title_1: "Bonus de bienvenue",
   home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">100 % + 500 tours gratuits !</span></>,
   home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">"+ 30 tours gratuits"</span> avec <span className="subtitle-lg home_baner-promo_font">"sans dépôt"</span></>,
   home_banner_log_in_title: "Bienvenue sur Orozino !",
   home_banner_log_in_title_name: function (name) { return `Bienvenue, ${name}` },
   home_banner_log_in_text: "Que vous trouviez de l'or aujourd'hui !",
   home_banner_log_in_deposit_text: "Que vous trouviez de l'or aujourd'hui !",
   home_banner_log_in_deposit_cta: "Déposez maintenant",
   home_banner_log_in_vip_banner_surtitle: "Une expérience de casino de luxe",
    home_banner_log_in_vip_banner_title: <>Déposez un total de <span className="primary"><b>500 $</b></span> pour obtenir des <span className="primary"><b>avantages VIP</b></span>.</>,
    home_banner_log_in_vip_banner_text: (
        <>
            <li>Payeurs de haute priorité</li>
            <li>Cashback mensuel de 30 % ou plus</li>
            <li>Bonus et cadeaux exclusifs</li>
            <li>Gestionnaire personnel</li>
        </>
    ),

   // ResponsibleGaming.js
   responsible_gaming_title: "Jeu responsable",
   responsible_gaming_text: "Nous croyons qu'il est de notre responsabilité, envers nos joueurs et la communauté de jeux, d'éduquer et de fournir un soutien à tout moment.",
   responsible_gaming_cta: "En savoir plus",

   // FirstDepositPromo.js
   registration_banner_title: <>Obtenez <span className="verify-phone-title-spins">30 tours gratuits</span>aucun dépôt requis !</>,
   registration_banner_subtitle: "AUCUN DÉPÔT REQUIS",
   fd_promo_deposit_title_interac: "Sécurisé et Protégé avec Interac !",
   fd_promo_deposit_subtitle_interac: "®Marque déposée d'Interac Corp. Utilisée sous licence.",
   fd_promo_deposit_title_pix: "Sûr et sécurisé avec PIX !",
   fd_promo_deposit_subtitle_pix: "Utilisé sous licence. Partenaire de paiement officiel.",
   deposit_banner_title_lottery: "Complétez et gagnez jusqu'à",
   deposit_banner_vip_title: <>Déposez un total de <span className="primary"><b>500 $</b></span><br />pour obtenir des <span className="primary"><b>avantages VIP</b></span>.</>,

   // GoogleButton.js
   google_button_text: "Continuer avec Google",

   // Footer.js
   footer_legal: "Orozino.com est exploité par Spinsoft Interactive N.V. (Numéro d'enregistrement : 160756), Abraham de Veerstraat 9, Willemstad, Boîte postale 3421, Curaçao, qui détient tous les droits d'exploiter le logiciel de jeu et/ou d'offrir des jeux de hasard sous la licence Anjouan n° ALS l-192407033-Fl2. Pour toute aide, veuillez nous contacter à info@orozinosupport.com. Jouez de manière responsable.",
   footer_legal_mock: <span>
       Ce site et son contenu sont protégés par les lois sur la propriété intellectuelle. Tous les droits sont réservés, y compris les droits de reproduction, distribution et adaptation, conformément à la législation sur le droit d'auteur.
       <br /><br />
       Le jeu responsable joue un rôle essentiel en garantissant une expérience positive et agréable pour les participants. Il favorise des pratiques sûres qui aident les joueurs à gérer leurs activités dans leurs limites, protégeant ainsi contre les risques d'addiction et les défis associés, tout en contribuant au bien-être général de la communauté en créant un environnement de jeu sûr, inclusif et responsable.
       <br /><br />
       Droits d'auteur © 2024 «Orozino»
       Tous droits réservés et protégés par la loi.
   </span>,
   footer_btn_terms: "Conditions générales",
   footer_btn_privacy_policy: "Politique de confidentialité",
   footer_btn_responsible_gaming: "Jeu responsable",
   footer_btn_dispute_resolution: "Résolution des litiges",
   footer_btn_self_exclusion: "Exclusion volontaire",
   footer_btn_terms_href: "https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions",
   footer_btn_privacy_policy_href: "https://cepgucehw.gleap.help/en/articles/4-privacy-policy",
   footer_btn_responsible_gaming_href: "https://cepgucehw.gleap.help/en/articles/3-responsible-gaming",
   footer_btn_dispute_resolution_href: "https://cepgucehw.gleap.help/en/articles/5-dispute-resolution",
   footer_btn_self_exclusion_href: "https://cepgucehw.gleap.help/en/articles/2-self-exclusion",
   footer_btn_affiliates: "Affiliates",
   footer_btn_affiliates_href: "https://www.spingoldaffiliates.com/",

   // QuestionMarks
   question_mark_bonus: (
       <>
           <ul>
               <li>Mise x30.</li>
               <li>Retrait sans limite.</li>
               <li>Bonus valable pendant 30 jours.</li>
           </ul>
           <p className="mb-0">Les tours gratuits sont disponibles instantanément.</p>
       </>
   ),
   question_mark_pick_and_win: (
       <ul>
           <li>Le jeu "Pick & Win" est disponible après chaque dépôt.</li>
           <li>Pour recevoir votre prix en argent instantanément, vous devez choisir l'une des trois cartes.</li>
           <li>Vous aurez 2 tentatives. Revenez le lendemain et tentez à nouveau votre chance.</li>
       </ul> 
   ),
   question_mark_deposit_lottery: (
       <ul>
           <li>Vous gagnerez votre argent instantanément en jouant au jeu exclusif "Pick & Win" d'Orozino.</li>
           <li>Pour recevoir votre prix en argent, vous devez choisir l'une des trois cartes disponibles sur la page d'accueil.</li> 
           <li>Le jeu "Pick & Win" est disponible instantanément après votre premier dépôt.</li>
       </ul>
   ),
   question_mark_jackpot: (
       <ul>
           <li>Les jackpots quotidiens et horaires sont attribués aléatoirement aux joueurs lors d'un tour (pari).</li>
           <li>Chaque tour (pari) participe au tirage des jackpots.</li>
       </ul>
   ),
   question_mark_bonus_balance: (
       <ul>
           <li>Le bonus doit être misé x30 avant que les fonds ne soient disponibles pour le retrait.</li>
           <li>Chaque tour (pari) éligible contribue à 100 % à la réalisation des conditions de mise.</li>
           <li>Vous pouvez retirer vos fonds avant de remplir les conditions de mise du bonus, cependant, tout l'argent du bonus sera annulé.</li>
       </ul>
   ),

   // DepositMethod.js
   deposit_method_minimum_deposit_amount: "Le montant minimum pour les paiements ci-dessous est",

   //RetentionModals.js
   welcome_modal_cta: "Réclamer maintenant",

   //HomeBannerTheme.js
   home_banner_log_out_surtile_theme: "OFFRE DE BIENVENUE",
   home_banner_log_out_title_theme: "100 % + 500 tours gratuits!",
   home_banner_log_out_subtitle_theme: <><span className="primary">+ 30 tours gratuits sans dépôt !</span></>,
   home_banner_log_out_subtitle_theme_10: <><span className="primary">+ 10 tours gratuits sans dépôt !</span></>,
   home_banner_log_out_subtitle_theme_50: <><span className="primary">+ 50 tours gratuits sans dépôt !</span></>,
   home_banner_log_out_subtitle_theme_100: <><span className="primary">+ 100 tours gratuits sans dépôt !</span></>,
   home_banner_log_out_subtitle_theme_custom: (spinAmount) => <span className="primary">{`+ ${spinAmount} tours gratuits sans dépôt !`}</span>,
   home_banner_log_out_cta_theme: "Réclamer maintenant"
}