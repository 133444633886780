export const de_brand = {
    /******************/
    /** Page titles */

    page_home: "Startseite | Orozino",
    page_games: "Spiele | Orozino",
    page_game: "Spiel | Orozino",
    page_login: "Anmelden | Orozino",
    page_registration: "Registrieren | Orozino",
    page_cashier: "Kasse | Orozino",
    page_profile: "Profil | Orozino",
    page_forgot_password: "Passwort vergessen | Orozino",
    page_reset_password: "Passwort zurücksetzen | Orozino",
    page_region_blocked: "Region blockiert | Orozino",
    page_verify_email: "E-Mail-Verifizierung | Orozino",

    /******************/
    /** Brand Pages */

    // Home
    last_played_10: "Steigen Sie wieder ein",
    last_played_20: "Zeitkapsel",

    /******************/
    /** Brand components */

    // CashbackRoll.js
    cashback_roll_title: "Pick & Win!",
    cashback_roll_title_deposit: "Laden Sie Ihr Konto auf und versuchen Sie Ihr Glück!",
    cashback_roll_subtitle: <span>Schalten Sie Ihren Gewinn frei, indem Sie Ihren ersten Bonus beanspruchen</span>,
    cashback_roll_subtitle_first_deposit: "Wählen Sie eine Karte und gewinnen Sie sofort Bargeld!",

    cashback_roll_next_ticket: "Nächste Auswahl verfügbar in:",
    cashback_roll_deposit_title: "Zahlen Sie ein und gewinnen Sie einen Sofortgeldpreis!",
    cashback_roll_btn_deposit: "Jetzt einzahlen",
    cashback_roll_tickets_left: "Tickets:",
    cashback_roll_you_won: "Sie haben gewonnen",
    cashback_roll_notification: ({ sign, amount }) => <span><span style={{ fontWeight: "600" }}>{sign}{amount}</span> wurde Ihrem Konto gutgeschrieben</span>,

    // HomeBanner.js
    home_banner_log_out_title_1: "Willkommensbonus",
    home_banner_log_out_title_2: <><span className="title-lg home_baner-promo_font">100 % + 500 Freispiele!</span></>,
    home_banner_log_out_title_3: <><span className="subtitle-lg home_baner-promo_font">"+ 30 Freispiele"</span> mit <span className="subtitle-lg home_baner-promo_font">"ohne Einzahlung"</span></>,
    home_banner_log_in_title: "Willkommen bei Orozino!",
    home_banner_log_in_title_name: function (name) { return `Willkommen, ${name}` },
    home_banner_log_in_text: "Möge Ihnen heute der große Gewinn gelingen!",
    home_banner_log_in_deposit_text: "Möge Ihnen heute der große Gewinn gelingen!",
    home_banner_log_in_deposit_cta: "Jetzt einzahlen",

    // ResponsibleGaming.js
    responsible_gaming_title: "Verantwortungsvolles Spielen",
    responsible_gaming_text: "Wir sind der Meinung, dass es unsere Verantwortung gegenüber unseren Spielern und der gesamten Gaming-Community ist, im richtigen Moment Aufklärung und Unterstützung zu bieten.",
    responsible_gaming_cta: "Erfahren Sie mehr",

    // FirstDepositPromo.js
    registration_banner_title: <>Sichern Sie sich<span className="verify-phone-title-spins">30 Freispiele</span>ohne Einzahlung!</>,
    registration_banner_subtitle: "KEINE EINZAHLUNG ERFORDERLICH",
    fd_promo_deposit_title_interac: "Sicher & zuverlässig mit Interac!",
    fd_promo_deposit_subtitle_interac: "®Markenzeichen von Interac Corp. Verwendet unter Lizenz.",
    fd_promo_deposit_title_pix: "Sicher & zuverlässig mit PIX!",
    fd_promo_deposit_subtitle_pix: "Verwendet unter Lizenz. Offizieller Zahlungspartner.",
    deposit_banner_title_lottery: "Zahlen Sie ein und gewinnen Sie bis zu",

    // GoogleButton.js
    google_button_text: "Weiter mit Google",

    // Footer.js
    footer_legal: "Orozino.com wird betrieben von Spinsoft Interactive N.V. (Reg-Nr. 160756), Abraham de Veerstraat 9, Willemstad, P.O. Box 3421, Curaçao, das alle Rechte besitzt, die Glücksspielsoftware zu betreiben und/oder Glücksspiele unter der Anjouan-Lizenz Nr. ALS l-192407033-Fl2 anzubieten. Bei Fragen wenden Sie sich bitte an info@orozinosupport.com. Spielen Sie verantwortungsvoll.",
    footer_legal_mock: <span>
        Diese Erklärung dient als formelle Bestätigung, dass die betreffenden Inhalte oder das geistige Eigentum urheberrechtlich geschützt sind. Sie besagt, dass der Urheber oder Rechteinhaber alle durch das Urheberrecht gewährten Privilegien vorbehalten hat, wie das Recht zur Vervielfältigung, Verbreitung und Anpassung des Werks.
        <br /><br />
        Verantwortungsvolles Spielen spielt eine entscheidende Rolle bei der Gewährleistung einer positiven und unterhaltsamen Erfahrung für die Teilnehmer. Es fördert sichere Praktiken, die es Einzelpersonen ermöglichen, ihre Aktivitäten innerhalb ihrer Grenzen zu kontrollieren, und schützt so vor den Risiken der Abhängigkeit und den damit verbundenen Herausforderungen. Gleichzeitig trägt es zum Wohl der Gemeinschaft bei, indem es eine sichere, inklusive und verantwortungsvolle Spielumgebung schafft.
        <br /><br />
        Copyright © 2025 «Orozino»
        Alle Rechte vorbehalten und gesetzlich geschützt.
    </span>,
    footer_btn_terms: "Allgemeine Geschäftsbedingungen",
    footer_btn_privacy_policy: "Datenschutzrichtlinie",
    footer_btn_responsible_gaming: "Verantwortungsvolles Spielen",
    footer_btn_dispute_resolution: "Streitbeilegung",
    footer_btn_self_exclusion: "Selbstausschluss",
    footer_btn_terms_href: "https://cepgucehw.gleap.help/en/articles/1-terms-and-conditions",
    footer_btn_privacy_policy_href: "https://cepgucehw.gleap.help/en/articles/4-privacy-policy",
    footer_btn_responsible_gaming_href: "https://cepgucehw.gleap.help/en/articles/3-responsible-gaming",
    footer_btn_dispute_resolution_href: "https://cepgucehw.gleap.help/en/articles/5-dispute-resolution",
    footer_btn_self_exclusion_href: "https://cepgucehw.gleap.help/en/articles/2-self-exclusion",
    footer_btn_affiliates: "Affiliates",
    footer_btn_affiliates_href: "https://www.spingoldaffiliates.com/",

    // QuestionMarks
    question_mark_bonus: (
        <>
            <ul>
                <li>Umsatzbedingungen x30.</li>
                <li>Keine Auszahlungslimits.</li>
                <li>Bonus ist 30 Tage gültig.</li>
            </ul>
            <p className="mb-0">Freispiele stehen sofort zur Verfügung.</p>
        </>
    ),
    question_mark_pick_and_win: (
        <ul>
            <li>Das "Pick & Win"-Spiel ist nach jeder Einzahlung verfügbar.</li>
            <li>Um Ihren Sofort-Cash-Preis zu erhalten, müssen Sie eine von drei Karten auswählen.</li>
            <li>Sie haben 2 Versuche. Kommen Sie am nächsten Tag zurück und versuchen Sie erneut Ihr Glück.</li>
        </ul>
    ),
    question_mark_deposit_lottery: (
        <ul>
            <li>Sie gewinnen Ihren Sofort-Cash-Preis, indem Sie das exklusive "Pick & Win"-Spiel von Orozino spielen.</li>
            <li>Um Ihren Cash-Preis zu erhalten, müssen Sie eine der drei Karten auswählen, die auf der Startseite verfügbar sind.</li>
            <li>Das "Pick & Win"-Spiel ist sofort nach Ihrer ersten Einzahlung verfügbar.</li>
        </ul>
    ),
    question_mark_jackpot: (
        <ul>
            <li>Tägliche und stündliche Jackpots werden Spielern nach einem Spin (Einsatz) zufällig zugeteilt.</li>
            <li>Jeder Spin (Einsatz) nimmt an der Jackpot-Auslosung teil.</li>
        </ul>
    ),
    question_mark_bonus_balance: (
        <ul>
            <li>Der Bonus muss x30 umgesetzt werden, bevor die Gelder für eine Auszahlung verfügbar sind.</li>
            <li>Jeder berechtigte Spin (Einsatz) trägt zu 100 % zur Erfüllung der Umsatzbedingungen bei.</li>
            <li>Sie können Ihre Gelder auszahlen lassen, bevor die Umsatzbedingungen für den Bonus erfüllt sind. Allerdings verfällt dabei sämtliches Bonusgeld.</li>
        </ul>
    ),

    // DepositMethod.js
    deposit_method_minimum_deposit_amount: "Der Mindestbetrag für Zahlungen beträgt",

    //RetentionModals.js
    welcome_modal_cta: "Jetzt beanspruchen",

    //HomeBannerTheme.js
    home_banner_log_out_surtile_theme: "WILLKOMMENSANGEBOT",
    home_banner_log_out_title_theme: "100% + 500 Freispiele!",
    home_banner_log_out_subtitle_theme: <><span className="primary">+ 30 Freispiele</span> mit<span className="primary">keine Einzahlung erforderlich</span></>,
    home_banner_log_out_subtitle_theme_custom: (spinAmount) => <><span className="primary">{`+ ${spinAmount} Freispiele`}</span> mit <span className="primary">keine Einzahlung erforderlich</span></>,
    home_banner_log_out_cta_theme: "Jetzt beanspruchen"
}