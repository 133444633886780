/** Theme components */
/** Default theme */
import RegistrationBanner from "./components/RegistrationBanner";
import DepositBanner from "./components/DepositBanner";

import EmailOptInModal from "./components/EmailOptInModal";

/** Big Bass Amazon */
import HomeBannerTheme from "./components/HomeBannerTheme";

/** Gates of Olympus */
import HomeBannerThemeOlympus from "./components/HomeBannerThemeOlympus";
import HomeBannerThemeOlympus10 from "./components/HomeBannerThemeOlympus10";
import RegistrationBannerThemeOlympus from "./components/RegistrationBannerThemeOlympus";
import DepositBannerThemeOlympus from "./components/DepositBannerThemeOlympus";

/** Brazil */
import HomeBannerThemeBrazil from "./components/HomeBannerThemeBrazil";
import RegistrationBannerThemeBrazil from "./components/RegistrationBannerThemeBrazil";
import DepositBannerThemeBrazil from "./components/DepositBannerThemeBrazil";

/** Crazy Time */
import HomeBannerThemeCrazyTime from "./components/HomeBannerThemeCrazyTime";
import RegistrationBannerThemeCrazyTime from "./components/RegistrationBannerThemeCrazyTime";
import DepositBannerThemeCrazyTime from "./components/DepositBannerThemeCrazyTime";

/** Dragon Kingdom */
import HomeBannerThemeDragonKingdom from "./components/HomeBannerThemeDragonKingdom";
import RegistrationBannerThemeDragonKingdom from "./components/RegistrationBannerThemeDragonKingdom";
import DepositBannerThemeDragonKingdom from "./components/DepositBannerThemeDragonKingdom";

/** VIP PPC */
import HomeBannerThemeVIP10 from "./components/HomeBannerThemeVIP10";
import RegistrationBannerThemeVIP10 from "./components/RegistrationBannerThemeVIP10";
import DepositBannerThemeVIP10 from "./components/DepositBannerThemeVIP10";
import WelcomeModalThemeVIP10 from "./components/WelcomeModalThemeVIP10";

/** Christmas */
import HomeBannerThemeChristmas from "./components/HomeBannerThemeChristmas";
import RegistrationBannerThemeChristmas from "./components/RegistrationBannerThemeChristmas";
import DepositBannerThemeChristmas from "./components/DepositBannerThemeChristmas";

import HomeBannerEgypt from "./components/HomeBannerThemeEgypt";
import RegistrationBannerThemeEgypt from "./components/RegistrationBannerThemeEgypt";
import DepositBannerThemeEgypt from "./components/DepositBannerThemeEgypt";

import HomeBannerEgypt0 from "./components/HomeBannerThemeEgypt0";
import RegistrationBannerThemeEgypt0 from "./components/RegistrationBannerThemeEgypt0";

import HomeBannerThemeWolfGold from "./components/HomeBannerThemeWolfGold";
import RegistrationBannerThemeWolfGold from "./components/RegistrationBannerThemeWolfGold";
import DepositBannerThemeWolfGold from "./components/DepositBannerThemeWolfGold";

import HomeBannerThemeBurningFortunator from "./components/HomeBannerThemeBurningFortunator";
import RegistrationBannerThemeBurningFortunator from "./components/RegistrationBannerThemeBurningFortunator";
import DepositBannerThemeBurningFortunator from "./components/DepositBannerThemeBurningFortunator";

import HomeBannerThemePlinko from "./components/HomeBannerThemePlinko";
import RegistrationBannerThemePlinko from "./components/RegistrationBannerThemePlinko";

/**
 * This is a function that returns components per each available theme
 * Not all of the keys have to be present. If the key is not present, the default key is used
 * 
 * @param {string} theme 
 * @param {string} key 
 */

export default function themes(theme, key) {
    try {
        const options = {
            // Default theme
            default: {
                home_banner: <HomeBannerTheme />,
                registration_banner: <RegistrationBanner />,
                deposit_banner: <DepositBanner />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-3.jpg",
                // TO DO:
                home_spotlight_category: "home-spotlight",
            },

            // Gates of Olympus
            olympus: {
                home_banner: <HomeBannerThemeOlympus spinAmount={30} />,
                registration_banner: <RegistrationBannerThemeOlympus />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_OLYMPUS',
                deposit_banner: <DepositBannerThemeOlympus />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-3.jpg",
            },

            olympus_hades: {
                home_banner: <HomeBannerThemeOlympus spinAmount={30} />,
                registration_banner: <RegistrationBannerThemeOlympus />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_HADES',
                deposit_banner: <DepositBannerThemeOlympus />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-3.jpg",
            },

            olympus_hades_75: {
                home_banner: <HomeBannerThemeOlympus spinAmount={75} />,
                registration_banner: <RegistrationBannerThemeOlympus />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_HADES_75',
                deposit_banner: <DepositBannerThemeOlympus />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-3.jpg",
            },

            olympus_10: {
                home_banner: <HomeBannerThemeOlympus10 />,
                registration_banner: <RegistrationBannerThemeOlympus />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_OLYMPUS_10',
                deposit_banner: <DepositBannerThemeOlympus />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/olympus/card-3.jpg",
            },

            // Brazil
            brazil: {
                home_banner: <HomeBannerThemeBrazil spinAmount={30} />,
                registration_banner: <RegistrationBannerThemeBrazil />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS',
                deposit_banner: <DepositBannerThemeBrazil />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-1.png",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-2.png",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-3.png",
            },

            brazil_55: {
                home_banner: <HomeBannerThemeBrazil spinAmount={55} />,
                registration_banner: <RegistrationBannerThemeBrazil />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_BIG_BASS_SPLASH_55',
                deposit_banner: <DepositBannerThemeBrazil />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-1.png",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-2.png",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/brazil/card-3.png",
            },

            // Crazy Time
            crazy_time: {
                home_banner: <HomeBannerThemeCrazyTime />,
                registration_banner: <RegistrationBannerThemeCrazyTime />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_CRAZY_TIME',
                deposit_banner: <DepositBannerThemeCrazyTime />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-3.jpg",
            },

            // Dragon Kingdom
            dragon_kingdom: {
                home_banner: <HomeBannerThemeDragonKingdom spinAmount={100} />,
                registration_banner: <RegistrationBannerThemeDragonKingdom />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_DRAGON_KINGDOM',
                deposit_banner: <DepositBannerThemeDragonKingdom />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-3.jpg",
            },

            dragon_kingdom_55: {
                home_banner: <HomeBannerThemeDragonKingdom spinAmount={55}/>,
                registration_banner: <RegistrationBannerThemeDragonKingdom />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_DRAGON_KINGDOM_55',
                deposit_banner: <DepositBannerThemeDragonKingdom />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/dragon_kingdom/card-3.jpg",
            },

            // VIP PPC
            vip_10: {
                home_banner: <HomeBannerThemeVIP10 />,
                registration_banner: <RegistrationBannerThemeVIP10 />,
                phone_verification_promocode: 'PHONE_VERIFICATION_BONUS_OLYMPUS_10',
                deposit_banner: <DepositBannerThemeVIP10 />,
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/vip_10/card-3.jpg",
                welcome_modal: <WelcomeModalThemeVIP10 />
            },

            // Big Bass Christmas
            big_bass_christmas: {
                home_banner: <HomeBannerThemeChristmas />,
                registration_banner: <RegistrationBannerThemeChristmas />,
                deposit_banner: <DepositBannerThemeChristmas />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_BIG_BASS_CHRISTMAS",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/christmas/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/christmas/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/christmas/card-3.jpg",
            },

            // Egypt
            egypt: {
                home_banner: <HomeBannerEgypt />,
                registration_banner: <RegistrationBannerThemeEgypt />,
                deposit_banner: <DepositBannerThemeEgypt />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_EGYPT",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-3.jpg",
            },

            egypt_0: {
                home_banner: <HomeBannerEgypt0 />,
                registration_banner: <RegistrationBannerThemeEgypt0 />,
                deposit_banner: <DepositBannerThemeEgypt />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_EGYPT",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-3.jpg",
            },

            // Book Of Gold 50
            book_of_gold_50: {
                home_banner: <HomeBannerEgypt />,
                registration_banner: <RegistrationBannerThemeEgypt />,
                deposit_banner: <DepositBannerThemeEgypt />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_BOOK_OF_GOLD_50",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/egypt/card-3.jpg",
            },

            // Wolf Gold
            wolf_gold: {
                home_banner: <HomeBannerThemeWolfGold spinAmount={30} />,
                registration_banner: <RegistrationBannerThemeWolfGold />,
                deposit_banner: <DepositBannerThemeWolfGold />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_WOLF_GOLD",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-3.jpg",
            },
            
            wolf_gold_50: {
                home_banner: <HomeBannerThemeWolfGold spinAmount={50} />,
                registration_banner: <RegistrationBannerThemeWolfGold />,
                deposit_banner: <DepositBannerThemeWolfGold />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_WOLF_GOLD_50",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/big_bass_amazon/card-3.jpg",
            },

            // Burning Fortunator 100
            burning_fortunator_100: {
                home_banner: <HomeBannerThemeBurningFortunator spinAmount={100} />,
                registration_banner: <RegistrationBannerThemeBurningFortunator />,
                deposit_banner: <DepositBannerThemeBurningFortunator />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_BURNING_FORTUNATOR_100",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-3.jpg",
            },

            // Plinko
            plinko: {
                home_banner: <HomeBannerThemePlinko spinAmount={100} />,
                registration_banner: <RegistrationBannerThemePlinko />,
                deposit_banner: <DepositBannerThemeBurningFortunator />,
                welcome_modal: null,
                phone_verification_promocode: "PHONE_VERIFICATION_BONUS_BURNING_FORTUNATOR_100",
                cashback_card_1: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-1.jpg",
                cashback_card_2: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-2.jpg",
                cashback_card_3: "https://spt-assets.b-cdn.net/orozino/themes/burning_fortunator/card-3.jpg",
            }

            // Add other themes
        }

        if (!key) {
            console.log(`Error, themes, key is not provided for ${theme} theme`);
            return null;
        }

        if (theme && options[theme]) {
            if (options[theme][key]) {
                return options[theme][key]
            }
        }

        return options.default[key];
    } catch (error) {
        console.log(`Error, themes, theme ${theme}, key ${key}: ${error}`);
    }
}

export function getLocationTheme(location) {
    let theme;
    
    /** More important goes in the end */
    /** Theme based on the ipCountryCode */
    if (location?.country_code === 'BR') {
        theme = 'brazil';
    }

    if (location?.country_code === 'FR') {
        theme = 'wolf_gold';
    }

    /** Theme based on the IP */

    return theme;
}