import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

/** Utilities */
import useText from "../spinsoft-core-client-subtree/utilities/use-text";

export default function DepositMethod({ setPaymentMethod, setPaymentSource, transitionToStep, amount }) {
    const user = useSelector((state) => state.user.data)
    const text = useText();

    return (
        <div className="deposit-payment-method-container">
            <h2 className="subtitle font-mid deposit-payment-method-title">{text('deposit_payment_method_title')}</h2>
            {
                {
                    'EUR': <PaymentMethods_EUR {...{ setPaymentMethod, setPaymentSource, transitionToStep }} />,
                    'USD': <PaymentMethods_USD {...{ setPaymentMethod, setPaymentSource, transitionToStep }} />,
                    'CAD': <PaymentMethods_CAD {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount }} />,
                    'AUD': <PaymentMethods_AUD {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount }} />,
                    'NZD': <PaymentMethods_NZD {...{ setPaymentMethod, setPaymentSource, transitionToStep }} />,
                    'BRL': <PaymentMethods_BRL {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount }} />,
                    'PEN': <PaymentMethods_PEN {...{ setPaymentMethod, setPaymentSource, transitionToStep }} />,
                }[user?.currency?.code]
            }
        </div>
    )
}

function PaymentMethodButton({ text, image, clickFn, disabled }) {
    return (
        <Button className="btn-secondary btn-choose-payment"
            onClick={clickFn}
            disabled={disabled || false}
        >
            <div>
                <img src={image} alt={text} />
                <span>{text}</span>
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
        </Button>
    )
}

/*************************/
/** EUR */
function PaymentMethods_EUR({ setPaymentMethod, setPaymentSource, transitionToStep }) {
    return (
        <>
            <PaymentMethodButton
                text='Visa / Mastercard'
                image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
                clickFn={() => { setPaymentMethod('payment_card_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
            />

            {/* <PaymentMethodButton
                text='TEST GMBP'
                image='https://cherryspins.b-cdn.net/orozino-test/pix.png'
                clickFn={() => { setPaymentMethod('test'); setPaymentSource('gmbp'); transitionToStep(3) }}
            /> */}

            <Crypto {...{ setPaymentMethod, setPaymentSource, transitionToStep }} />
        </>
    )
}

/*************************/
/** CAD */
function PaymentMethods_CAD({ setPaymentMethod, setPaymentSource, transitionToStep, amount }) {
    const text = useText();
    const minAmount = 15;

    return (
        <>
            <PaymentMethodButton
                text='Interac®'
                image='https://cherryspins.b-cdn.net/images/interac-color-logo-4.png'
                clickFn={() => { setPaymentMethod('interac_aggregated_cad_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
            />

            {
                amount < minAmount && (
                    <p className="font-mid mt-4 mb-3" style={{ fontSize: "12px" }}>{text('deposit_method_minimum_deposit_amount')} <b>C$ {minAmount}</b>.</p>
                )
            }

            <PaymentMethodButton
                text='Visa / Mastercard'
                image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
                clickFn={() => { setPaymentMethod('payment_card_cad_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                disabled={amount < minAmount}
            />

            <Crypto {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount, minAmount }} />
        </>
    )
}

/*************************/
/** AUD */
function PaymentMethods_AUD({ setPaymentMethod, setPaymentSource, transitionToStep, amount }) {
    const minAmount = 0;

    return (
        <>
            <PaymentMethodButton
                text='Visa / Mastercard'
                image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
                clickFn={() => { setPaymentMethod('payment_card_aud_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
            />

            <Crypto {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount, minAmount }} />
        </>
    )
}

/*************************/
/** BRL */
function PaymentMethods_BRL({ setPaymentMethod, setPaymentSource, transitionToStep, amount }) {
    const text = useText();
    const minAmount = 65;

    return (
        <>
            {/* <PaymentMethodButton
                text='Pix'
                image='https://cherryspins.b-cdn.net/orozino-test/pix.png'
                clickFn={() => { setPaymentMethod('pix_brl_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
            /> */}

            <PaymentMethodButton
                text='Pix'
                image='https://cherryspins.b-cdn.net/orozino-test/pix.png'
                clickFn={() => { setPaymentMethod('pix'); setPaymentSource('gmbp'); transitionToStep(3) }}
            />

            {/* <PaymentMethodButton
                text='Pix 3'
                image='https://cherryspins.b-cdn.net/orozino-test/pix.png'
                clickFn={() => { setPaymentMethod('pix_qr_brl_invoice'); setPaymentSource('crfy'); transitionToStep(3) }}
            /> */}

            {
                amount < minAmount && (
                    <p className="font-mid mt-4 mb-3" style={{ fontSize: "12px" }}>{text('deposit_method_minimum_deposit_amount')} <b>R$ {minAmount}</b>.</p>
                )
            }

            <PaymentMethodButton
                text='Visa / Mastercard'
                image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
                clickFn={() => { setPaymentMethod('payment_card_brl_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                disabled={amount < minAmount}
            />

            <Crypto {...{ setPaymentMethod, setPaymentSource, transitionToStep, amount, minAmount }} />
        </>
    )
}

/** PEN */
function PaymentMethods_PEN({ setPaymentMethod, setPaymentSource, transitionToStep }) {
    return (
        <PaymentMethodButton
            text='Visa / Mastercard'
            image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
            clickFn={() => { setPaymentMethod('payment_card_pen_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
        />
    )
}

/** NZD */
function PaymentMethods_NZD({ setPaymentMethod, setPaymentSource, transitionToStep }) {
    return (
        <PaymentMethodButton
            text='Visa / Mastercard'
            image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
            clickFn={() => { setPaymentMethod('payment_card_nzd_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
        />
    )
}

/********************/
/** Crypto options */
function Crypto({ setPaymentMethod, setPaymentSource, transitionToStep, amount, minAmount }) {
    const text = useText();
    const [show, setShow] = useState(false);

    return (
        <>
            <Button onClick={() => { setShow(false); setPaymentMethod('bitcoin_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3); }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin.png" alt="Bitcoin" />
                    <span>Bitcoin</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('bitcoin_cash_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount} >
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-bitcoin-cash.png" alt="Bitcoin Cash" />
                    <span>Bitcoin Cash</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('tether_trc20_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-trc20.png" alt="Tether TRC20" />
                    <span>Tether TRC20</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('tether_erc20_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-tether-erc20.png" alt="Tether ERC20" />
                    <span>Tether ERC20</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('ethereum_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-etherium.png" alt="Ethereum" />
                    <span>Ethereum</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('ripple_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-ripple.png" alt="Ripple" />
                    <span>Ripple</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            <Button onClick={() => { setShow(false); setPaymentMethod('litecoin_eur_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid"
                disabled={amount < minAmount}>
                <div>
                    <img src="https://spt-assets.b-cdn.net/orozino/assets/payment-logo-litecoin.png" alt="Litecoin" />
                    <span>Litecoin</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
            {/* </Modal.Body>
            </Modal> */}
        </>
    )
}

/*************************/
/** USD */
function PaymentMethods_USD({ setPaymentMethod, setPaymentSource, transitionToStep }) {
    return (
        <PaymentMethodButton
            text='Visa / Mastercard'
            image='https://cherryspins.b-cdn.net/images/visa-logo-3.png'
            clickFn={() => { setPaymentMethod('payment_card_usd_hpp'); setPaymentSource('crfy'); transitionToStep(3) }}
        />
    )
}